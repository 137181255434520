import { ToastError } from '@/Components/toast';
import { CHECKEMAIL_QUERY } from '@/Lib/graphql/queries/auth0.query';
import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useDevice } from '../useDevice';
import { TFields, Tabs, sharedData } from '@/Lib/types/fpdc';

type Props = {
  fields: TFields;
  checkFields: any;
  setTab: any;
  setDisabledEmail: any;
  setTemplateUpdateData: any;
};

const useInitEmail = ({
  fields,
  checkFields,
  setTab,
  setDisabledEmail,
  setTemplateUpdateData,
}: Props) => {
  const { isMD } = useDevice();
  const [loading, setLoading] = useState(false);

  const cacheControlHeader = {
    headers: {
      'Cache-Control': 'max-age=0',
    },
  };

  const [
    checkEmail,
    {
      loading: checkEmailLoading,
      data: checkEmailData,
      error: checkEmailError,
    },
  ] = useMutation<{
    checkEmail: {
      isSocial: boolean;
      success: boolean;
      name: string;
      code: string;
      message: string;
    };
  }>(CHECKEMAIL_QUERY);

  const checkInitEmail = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const hasErrors = checkFields(Tabs.init);
    if (hasErrors) return;
    setLoading(true);

    checkEmail({
      variables: {
        email: fields.email.toLowerCase(),
      },
      context: cacheControlHeader,
    });
  };

  function handleKeyPress(event: any) {
    if (event.key === 'Enter') {
      checkEmail(event);
    }
  }

  useEffect(() => {
    if (!checkEmailLoading && checkEmailError) {
      setLoading(false);
      ToastError('Something went wrong, please try later...', isMD as boolean);
    }

    if (!checkEmailLoading && !checkEmailError && checkEmailData) {
      setLoading(false);
      if (checkEmailData.checkEmail.code && checkEmailData.checkEmail.message) {
        ToastError(checkEmailData.checkEmail.message, isMD as boolean);
        return;
      }

      if (checkEmailData.checkEmail.success) {
        setTemplateUpdateData({ name: checkEmailData.checkEmail.name });
        if (checkEmailData.checkEmail.isSocial) {
          setTab(Tabs.loginSocial);
        } else {
          setTab(Tabs.login);
          setDisabledEmail(true);
        }
      } else {
        setTab(Tabs.register);
        setDisabledEmail(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkEmailLoading, checkEmailData, checkEmailError]);

  return { handleKeyPress, checkInitEmail, loading };
};
export { useInitEmail };

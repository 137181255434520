import { gql } from '@apollo/client';

export const LOGIN_TEMPLATE_COLLECTION = gql`
  query LoginTemplateCollection {
    loginTemplateCollection {
      items {
        type
        title
        description
        buttonTitle
      }
    }
  }
`;

export type TFields = {
  password: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneCode: string;
  phoneNumber: string;
};

export enum Tabs {
  login = 'login',
  register = 'register',
  forgetPass = 'forget-pass',
  init = 'init-form',
  loginSocial = 'social-login',
}

export type sharedData = {
  name: string;
};

export const defaultSharedData: sharedData = {
  name: '',
};

import { useTimer } from '@/Lib/hooks/useTimer';
import { useEffect } from 'react';
import { useSetAtom } from 'jotai';
import { promoUpdateTriggerAtom } from '@/State/global/global.store';
import { Promotion } from '../types/trip';

const usePromoTimer = (promo: Promotion | null, enabled: boolean) => {
  const setPromoUpdateTrigger = useSetAtom(promoUpdateTriggerAtom);

  const { isEnabled, remaining } = useTimer({
    expiry: promo?.expires ?? 0,
    enabled,
  });

  useEffect(() => {
    setPromoUpdateTrigger((prev) => ++prev);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEnabled]);

  return { isEnabled, remaining };
};

export { usePromoTimer };

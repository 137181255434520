import {
  getEmailValidation,
  getLengthPassCharValidation,
  getNameValidation,
  getNumberValidation,
} from '@/Lib/helpers/validation';
import { TFields, Tabs } from '@/Lib/types/fpdc';
import { useState } from 'react';

const useValidation = () => {
  const [fields, setFields] = useState<TFields>({
    password: '',
    email: '',
    firstName: '',
    lastName: '',
    phoneCode: '',
    phoneNumber: '',
  });

  const [errors, setErrors] = useState<TFields>({
    password: '',
    email: '',
    firstName: '',
    lastName: '',
    phoneCode: '',
    phoneNumber: '',
  });

  function checkFields(
    tab: Tabs,
    field?: string,
    fieldValue?: string
  ): boolean {
    let response = errors;

    if (
      tab === Tabs.register &&
      (!field || (field && field === 'firstName')) &&
      (field ? fieldValue?.length : fields.firstName.length) === 0
    ) {
      response = { ...response, firstName: 'This field is required' };
    } else if (!field || (field && field === 'firstName')) {
      response = { ...response, firstName: '' };
    }

    if (
      tab === Tabs.register &&
      (!field || (field && field === 'firstName')) &&
      ((field ? fieldValue?.length : fields.firstName.length) || 0 > 0) &&
      !getNameValidation((field ? fieldValue : fields.firstName) || '')
    ) {
      response = {
        ...response,
        firstName: 'Please provide a valid name',
      };
    } else if (
      (!field || (field && field === 'firstName')) &&
      ((field ? fieldValue?.length : fields.firstName.length) || 0 > 0)
    ) {
      response = { ...response, firstName: '' };
    }

    if (
      tab === Tabs.register &&
      (!field || (field && field === 'lastName')) &&
      (field ? fieldValue?.length : fields.lastName.length) === 0
    ) {
      response = { ...response, lastName: 'This field is required' };
    } else if (!field || (field && field === 'lastName')) {
      response = { ...response, lastName: '' };
    }

    if (
      tab === Tabs.register &&
      (!field || (field && field === 'lastName')) &&
      ((field ? fieldValue?.length : fields.lastName.length) || 0 > 0) &&
      !getNameValidation((field ? fieldValue : fields.lastName) || '')
    ) {
      response = {
        ...response,
        lastName: 'Please provide a valid last name',
      };
    } else if (
      (!field || (field && field === 'lastName')) &&
      ((field ? fieldValue?.length : fields.lastName.length) || 0 > 0)
    ) {
      response = { ...response, lastName: '' };
    }

    if (
      tab === Tabs.register &&
      (!field || (field && field === 'phoneNumber')) &&
      ((field ? fieldValue?.length : fields.phoneNumber.length) || 0 > 0) &&
      !getNumberValidation((field ? fieldValue : fields.phoneNumber) || '')
    ) {
      response = {
        ...response,
        phoneNumber: 'Please provide a valid phone number',
      };
    } else if (!field || (field && field === 'phoneNumber')) {
      response = { ...response, phoneNumber: '' };
    }

    if (
      (field ? fieldValue?.length : fields.email.length) === 0 &&
      (!field || (field && field === 'email'))
    ) {
      response = { ...response, email: 'This field is required' };
    } else if (!field || (field && field === 'email')) {
      response = { ...response, email: '' };
    }

    if (
      !getEmailValidation(field ? fieldValue || '' : fields.email) &&
      (!field || (field && field === 'email'))
    ) {
      response = {
        ...response,
        email: 'Please provide a valid email address',
      };
    } else if (!field || (field && field === 'email')) {
      response = {
        ...response,
        email: '',
      };
    }

    if (
      tab !== Tabs.forgetPass &&
      (!field || (field && field === 'password')) &&
      tab !== Tabs.init &&
      (field ? fieldValue?.length : fields.password.length) === 0
    ) {
      response = {
        ...response,
        password: 'This field is required',
      };
    } else if (!field || (field && field === 'password')) {
      response = {
        ...response,
        password: '',
      };
    }

    if (
      tab !== Tabs.forgetPass &&
      tab !== Tabs.init &&
      (!field || (field && field === 'password')) &&
      !getLengthPassCharValidation(field ? fieldValue || '' : fields.password)
    ) {
      response = {
        ...response,
        password: 'Must have 6 characters in length',
      };
    } else if (!field || (field && field === 'password')) {
      response = {
        ...response,
        password: '',
      };
    }

    setErrors(response);
    if (
      !response.email &&
      !response.password &&
      !response.firstName &&
      !response.lastName &&
      !response.phoneCode &&
      !response.phoneNumber
    ) {
      return false;
    }
    return true;
  }

  return { checkFields, fields, setFields, errors, setErrors };
};
export { useValidation };

import {
  flagsAreLoadingAtom,
  userLoginStatusAtom,
} from '@/State/global/global.store';
import { useAtomValue } from 'jotai';
import { currencySymbols } from 'data/currency';
import { useCurrency } from '@/Lib/hooks/useCurrency';
import { Promotion } from '@/Lib/types/trip';
import { useTimerPromo } from '@/Lib/hooks/useTimerPromo';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { usePromoTimer } from '@/Lib/hooks/usePromoTimer';
import { useExperimentalFeature } from '@/Lib/hooks/useExperimentalFeature';
import { AB_TEST_FEATURE } from 'data/flags';
dayjs.extend(duration);

type Props = {
  discount: number;
  price: number;
  discountedPrice: number;
  promos: Promotion[];
};

const Price = ({ discount, price, discountedPrice, promos }: Props) => {
  const flagsAreLoading = useAtomValue(flagsAreLoadingAtom);
  const { currency, currencyShouldBeShown } = useCurrency();
  const shouldShowPromoTimer = useExperimentalFeature(
    AB_TEST_FEATURE.UB_promotion_timer,
    'new_version'
  );

  const timerPromo = useTimerPromo(promos);
  const { isEnabled: isPromoTimerEnabled, remaining } = usePromoTimer(
    timerPromo,
    shouldShowPromoTimer
  );

  // const isUserLoggedIn = useAtomValue(userLoginStatusAtom);
  const isUserLoggedIn = true;

  return (
    <>
      {isUserLoggedIn === true && !flagsAreLoading && (
        <div className="flex flex-col gap-2.5">
          {isPromoTimerEnabled ? (
            <div className="flex items-center gap-2">
              <div className="py-0.5 px-1.5 bg-red-900 rounded-full text-light-600 text-2xs font-semibold leading-3.5">
                {currencySymbols[currency] + discount} off
              </div>
              <div className="text-sm font-semibold leading-3.5 text-red-900">
                Ends in:{' '}
                {dayjs.duration(remaining, 'seconds').format('HH:mm:ss')}
              </div>
            </div>
          ) : null}
          <div className="text-sm leading-3 font-semibold flex items-center justify-between flex-wrap w-full text-dark-700 space-x-1">
            {discount ? (
              <>
                <p className="text-sm space-x-1">
                  <span className="text-dark-700">From</span>
                  <span className="line-through text-dark-600">
                    {price?.toLocaleString()}
                  </span>
                  <span className="font-semibold text-dark-800">
                    {currencySymbols[currency]}
                    {discountedPrice?.toLocaleString()}
                    {currencyShouldBeShown ? (
                      <span className="text-xs relative -top-px ml-0.5">
                        ({currency})
                      </span>
                    ) : null}
                  </span>
                </p>
              </>
            ) : null}
            {!discount ? (
              <p className="text-sm text-dark-700">
                From {currencySymbols[currency]}
                {price?.toLocaleString()}
                {currencyShouldBeShown ? (
                  <span className="text-xs relative -top-px ml-0.5">
                    ({currency})
                  </span>
                ) : null}
              </p>
            ) : null}
          </div>
        </div>
      )}
      {/* {isUserLoggedIn === false && !flagsAreLoading && (
        <p className="text-base font-semibold text-dark-700 underline">
          See Inclusions & Pricing
        </p>
      )} */}
      {(isUserLoggedIn === null || flagsAreLoading) && (
        <div className="my-1 h-5 w-12 bg-light-900  animate-pulse "></div>
      )}
    </>
  );
};

export { Price };

import { Promotion } from '../types/trip';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useCurrency } from './useCurrency';
dayjs.extend(utc);

const useTimerPromo = (promos: Promotion[]): Promotion | null => {
  const now = dayjs.utc().unix();
  const cutoff = 24 * 60 * 60;
  const { currency } = useCurrency();

  const filtered = promos.filter((p) => p.expires - now <= cutoff);
  const sorted = filtered.sort((a, b) => {
    if (a.amount && b.amount) {
      const diff = b.amount[currency] - a.amount[currency];
      if (diff) return diff;
    }
    return b.expires - a.expires;
  });
  const promo = sorted.length ? sorted[0] : null;

  return promo;
};

export { useTimerPromo };

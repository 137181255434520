import { ModalCentered } from '../modals/modal-centered';

type Props = {
  children: any;
  isOpen: boolean;
  setIsOpen: any;
};

const LoginModal = ({ children, isOpen, setIsOpen }: Props) => {
  return (
    <ModalCentered
      isModalOpen={isOpen}
      setIsModalOpen={setIsOpen}
      paddingClass="p-0"
      noCloseButton={true}
      hasPreventDefault={false}
      hasMdRoudedBorders={true}
      closeClickOut={false}
      zIndex={1001}
    >
      {children}
    </ModalCentered>
  );
};

export { LoginModal };

import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
dayjs.extend(utc);
dayjs.extend(duration);

type Props = {
  expiry: number; // unix epoch in seconds
  enabled: boolean;
};

type ReturnType = {
  isEnabled: boolean;
  remaining: number;
};

const useTimer = ({ expiry, enabled }: Props): ReturnType => {
  const [remaining, setRemaining] = useState<number>(0);

  useEffect(() => {
    if (!expiry || !enabled) return;

    function calculateRemaining(): number {
      const now = dayjs().utc().unix();
      const diff = expiry - now;
      return diff;
    }

    const interval = setInterval(() => {
      const remaining = calculateRemaining();
      setRemaining(remaining);

      if (remaining <= 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [expiry, enabled]);

  if (!expiry || !enabled) return { isEnabled: false, remaining: 0 };

  return { isEnabled: remaining > 0, remaining };
};

export { useTimer };

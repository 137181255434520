type Props = {
  className: string;
};

export const HeartSVG = ({ className }: Props) => {
  const hurtPath = `M12 21C11.7 21 11.5 20.9 11.2 20.7C10.4 20 9.6 19.3 8.9 18.7C6.9 17 5.2 15.5 4 14C2.7 12.4 2 10.8 2 9.10002C2 7.40002 2.5 5.90002 3.6 4.80002C4.7 3.60002 6.1 3.00002 7.6 3.00002C8.7 3.00002 9.8 3.30002 10.7 4.10002C11.2 4.40002 11.6 4.90002 12 5.40002C12.4 4.90002 12.8 4.50002 13.3 4.00002C14.2 3.30002 15.3 2.90002 16.4 2.90002C17.9 2.90002 19.3 3.50002 20.4 4.70002C21.4 5.90002 22 7.30002 22 9.00002C22 10.7 21.4 12.3 20 13.9C18.8 15.4 17.1 16.9 15.1 18.6C14.4 19.2 13.6 19.9 12.8 20.6C12.5 20.9 12.3 21 12 21Z`;
  return (
    <svg viewBox="0 0 24 24" fill="none" className={className}>
      <path d={hurtPath} />
    </svg>
  );
};

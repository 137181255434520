type Props = {
  message: string;
};
const ResultMessage = ({ message }: Props) => {
  return message ? (
    <div className="mt-3 text-2xs font-semibold leading-4 text-center text-[#259D68]">
      {message}
    </div>
  ) : (
    <></>
  );
};

export { ResultMessage };

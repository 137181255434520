const Eye = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5914 10.4101C12.7126 9.5305 11.2878 9.5301 10.4076 10.4101C9.52887 11.2905 9.52954 12.7167 10.4072 13.5945C11.2877 14.4732 12.7139 14.4724 13.5917 13.5947C14.4702 12.7142 14.4692 11.2878 13.5914 10.4101ZM14.6515 9.34881C13.1863 7.88358 10.8121 7.88426 9.34667 9.34967C7.88363 10.8152 7.88253 13.1912 9.34667 14.6553C10.8122 16.1184 13.1882 16.1195 14.6523 14.6553C16.1153 13.1899 16.1165 10.8141 14.6526 9.3499"
        fill="#575B63"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 5.75C8.56983 5.75 5.49798 7.60008 4.11971 10.4176C3.87516 10.9175 3.75 11.4569 3.75 12C3.75 12.5431 3.87516 13.0825 4.11971 13.5824C5.49798 16.3999 8.56983 18.25 12 18.25C15.4302 18.25 18.502 16.3999 19.8803 13.5824C20.1248 13.0825 20.25 12.5431 20.25 12C20.25 11.4569 20.1248 10.9175 19.8803 10.4176C18.502 7.60008 15.4302 5.75 12 5.75ZM2.77229 9.75843C4.42402 6.38192 8.04817 4.25 12 4.25C15.9518 4.25 19.576 6.38192 21.2277 9.75843C21.5712 10.4605 21.75 11.2251 21.75 12C21.75 12.7749 21.5712 13.5395 21.2277 14.2416C19.576 17.6181 15.9518 19.75 12 19.75C8.04817 19.75 4.42402 17.6181 2.77229 14.2416C2.42884 13.5395 2.25 12.7749 2.25 12C2.25 11.2251 2.42884 10.4605 2.77229 9.75843Z"
        fill="#575B63"
      />
    </svg>
  );
};

const EyeCross = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0891 9.06191C11.3003 9.41803 11.1829 9.87794 10.8267 10.0891C10.0181 10.5688 9.59248 11.5003 9.75936 12.4256C9.92624 13.3509 10.6504 14.0751 11.5757 14.242C12.501 14.4088 13.4326 13.9833 13.9122 13.1746C14.1234 12.8185 14.5833 12.701 14.9394 12.9122C15.2955 13.1234 15.413 13.5833 15.2018 13.9394C14.4028 15.2866 12.851 15.9955 11.3096 15.7175C9.76821 15.4395 8.56179 14.2331 8.28379 12.6917C8.0058 11.1503 8.71474 9.59848 10.0619 8.79952C10.418 8.58831 10.8779 8.70579 11.0891 9.06191Z"
        fill="#575B63"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.88462 5.87079C8.07895 6.23639 7.9401 6.69031 7.5745 6.88464C6.08344 7.67717 4.87534 8.91321 4.11709 10.4219C3.6279 11.4153 3.62713 12.5793 4.11477 13.5732C5.64018 16.4978 8.68808 18.3091 11.9866 18.2504L12.0022 18.2502C14.0017 18.2563 15.9478 17.6051 17.5409 16.3967C17.8708 16.1465 18.3411 16.2111 18.5913 16.541C18.8415 16.8708 18.7769 17.3411 18.447 17.5913C16.5941 18.9968 14.331 19.755 12.0054 19.7496C8.13848 19.8155 4.56638 17.6892 2.78088 14.258L2.7736 14.2436C2.07579 12.8292 2.07579 11.1707 2.7736 9.75624L2.77594 9.7515C3.67357 7.96394 5.1045 6.49949 6.87078 5.56067C7.23638 5.36634 7.69029 5.50519 7.88462 5.87079Z"
        fill="#575B63"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9928 4.25025C15.8604 4.18373 19.4332 6.31016 21.219 9.74186L21.2263 9.75623C21.9241 11.1706 21.9241 12.8292 21.2263 14.2436C21.1886 14.3202 21.1475 14.3855 21.1251 14.4211L21.1241 14.4227C21.0956 14.468 21.0903 14.4766 21.0865 14.4838C20.8931 14.8499 20.4395 14.9898 20.0734 14.7964C19.7074 14.6029 19.5674 14.1494 19.7609 13.7833C19.7937 13.7212 19.8302 13.6635 19.8496 13.6328C19.8517 13.6295 19.8535 13.6265 19.8552 13.6239C19.8768 13.5896 19.8807 13.5821 19.8807 13.5822L19.8816 13.5802C20.3719 12.5864 20.3731 11.4213 19.8851 10.4267C18.3597 7.502 15.3118 5.69076 12.0133 5.7495L11.9999 5.74974C11.8813 5.74974 11.7898 5.75812 11.6711 5.76914C11.5926 5.77644 11.5021 5.78484 11.384 5.79262C10.9708 5.81984 10.6138 5.50698 10.5866 5.09383C10.5594 4.68069 10.8723 4.3237 11.2854 4.29649C11.3264 4.29378 11.3805 4.28899 11.4426 4.28349C11.6012 4.26944 11.8123 4.25074 11.9928 4.25025Z"
        fill="#575B63"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.46981 2.46982C3.76258 2.17705 4.23725 2.17705 4.53003 2.46982L21.53 19.4698C21.8228 19.7626 21.8228 20.2373 21.53 20.53C21.2373 20.8228 20.7626 20.8228 20.4698 20.53L3.46981 3.53004C3.17704 3.23727 3.17704 2.76259 3.46981 2.46982Z"
        fill="#575B63"
      />
    </svg>
  );
};

const Apple = () => {
  return (
    <svg
      style={{ width: '100%', height: '100%' }}
      viewBox="0 0 35 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.1174 2.26672C23.1853 2.26672 23.2533 2.26672 23.325 2.26672C23.4917 4.34477 22.7059 5.89749 21.7509 7.02192C20.8138 8.13858 19.5306 9.2216 17.4552 9.05727C17.3167 7.00898 18.1038 5.57142 19.0576 4.44958C19.9421 3.40409 21.5637 2.47375 23.1174 2.26672Z"
        fill="#333539"
      />
      <path
        d="M29.4 23.896C29.4 23.9167 29.4 23.9348 29.4 23.9543C28.8167 25.7373 27.9848 27.2654 26.9695 28.6836C26.0427 29.971 24.9069 31.7036 22.8789 31.7036C21.1265 31.7036 19.9626 30.5662 18.1666 30.5352C16.2668 30.5041 15.222 31.4862 13.4851 31.7334C13.2864 31.7334 13.0877 31.7334 12.8928 31.7334C11.6173 31.547 10.5879 30.5274 9.83801 29.6087C7.62671 26.8941 5.91792 23.3875 5.60001 18.9002C5.60001 18.4602 5.60001 18.0216 5.60001 17.5817C5.73461 14.3701 7.28059 11.759 9.3355 10.4935C10.42 9.82066 11.9109 9.24745 13.5709 9.50365C14.2824 9.61493 15.0092 9.86078 15.6464 10.104C16.2501 10.3382 17.0052 10.7536 17.7205 10.7316C18.2051 10.7174 18.6871 10.4625 19.1755 10.2826C20.6061 9.76114 22.0085 9.16335 23.857 9.44413C26.0786 9.78314 27.6553 10.7795 28.6296 12.3167C26.7503 13.5239 25.2646 15.3432 25.5184 18.4499C25.744 21.2719 27.3695 22.923 29.4 23.896Z"
        fill="#333539"
      />
    </svg>
  );
};

const Google = () => {
  return (
    <svg
      style={{ width: '100%', height: '100%' }}
      viewBox="0 0 35 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.76 17.3262C30.76 16.4077 30.6716 15.4596 30.5242 14.5707H17.7616V19.815H25.0713C24.7766 21.5039 23.8039 22.9853 22.3596 23.9335L26.7219 27.3408C29.2862 24.9408 30.76 21.4446 30.76 17.3262Z"
        fill="#4280EF"
      />
      <path
        d="M17.7616 30.6C21.4165 30.6 24.4818 29.3852 26.7219 27.3112L22.3596 23.9335C21.1512 24.7631 19.589 25.2372 17.7616 25.2372C14.2246 25.2372 11.2476 22.8372 10.1571 19.6373L5.67688 23.1039C7.97592 27.6964 12.6329 30.6 17.7616 30.6Z"
        fill="#34A353"
      />
      <path
        d="M10.1571 19.6076C9.59705 17.9188 9.59705 16.0818 10.1571 14.3929L5.67689 10.8967C3.76102 14.7484 3.76102 19.2817 5.67689 23.1038L10.1571 19.6076Z"
        fill="#F6B704"
      />
      <path
        d="M17.7616 8.79303C19.6774 8.7634 21.5638 9.50413 22.9491 10.8374L26.8103 6.9264C24.3639 4.61533 21.1217 3.37091 17.7616 3.40054C12.6329 3.40054 7.97592 6.30419 5.67688 10.8967L10.1571 14.3929C11.2476 11.1634 14.2246 8.79303 17.7616 8.79303Z"
        fill="#E54335"
      />
    </svg>
  );
};

const Facebook = () => {
  return (
    <svg
      style={{ width: '100%', height: '100%' }}
      viewBox="0 0 35 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.069 31.586C8.07066 30.3337 2.76666 24.293 2.76666 17C2.76666 8.89671 9.39665 2.26672 17.5 2.26672C25.6033 2.26672 32.2333 8.89671 32.2333 17C32.2333 24.293 26.9293 30.3337 19.931 31.586L19.1206 30.923H15.8793L15.069 31.586Z"
        fill="url(#paint0_linear_227_1403)"
      />
      <path
        d="M23.246 21.1255L23.909 17.0001H20.0046V14.1271C20.0046 12.9485 20.4466 12.0645 22.2146 12.0645H24.13V8.30748C23.0986 8.16015 21.92 8.01282 20.8886 8.01282C17.5 8.01282 15.1426 10.0755 15.1426 13.7588V17.0001H11.4593V21.1255H15.1426V31.5125C15.953 31.6598 16.7633 31.7335 17.5736 31.7335C18.384 31.7335 19.1943 31.6598 20.0046 31.5125V21.1255H23.246Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_227_1403"
          x1="17.5007"
          y1="30.7086"
          x2="17.5007"
          y2="2.2613"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0062E0" />
          <stop offset="1" stopColor="#19AFFF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export { Eye, EyeCross, Apple, Google, Facebook };

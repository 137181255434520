import { CheckboxFilled } from '@/Components/checkbox/checkbox-filled';
type Props = {
  checkedCompare: boolean;
  compare: any;
  isCompareActive: boolean;
  tripId: string;
};

const ProductCardCompare = ({
  checkedCompare,
  compare,
  isCompareActive,
  tripId,
}: Props) => {
  return (
    <div className="flex items-center justify-start cursor-pointer  p-5 border-t border-light-700 lg:px-5 lg:border-none lg:py-0">
      <CheckboxFilled
        isChecked={checkedCompare}
        toggleChecked={() => compare(tripId)}
        id={tripId.toString()}
        isEnabled={isCompareActive || checkedCompare}
      />
      <div
        className="ml-2.5 font-semibold text-sm text-dark-700"
        onClick={() => compare(tripId)}
      >
        Compare
      </div>
    </div>
  );
};
export { ProductCardCompare };

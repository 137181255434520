import Select, { createFilter } from 'react-select';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { TDropDownItem } from './types';
import 'flagpack/dist/flagpack.css';

const sortAlphabetically = (a: any, b: any) => {
  if (a.countryName < b.countryName) {
    return -1;
  }
  if (a.countryName > b.countryName) {
    return 1;
  }
  return 0;
};

type DropdownSingleValueProps = {
  id: string;
  loading?: boolean;
  options: TDropDownItem[];
  setOption: Function;
  placeholder?: string;
  hasFlagImage?: boolean;
  defaultOption?: TDropDownItem | null;
  isSearchable?: boolean;
  hasError?: boolean;
  height?: string;
  hasIndicator?: boolean;
};

function DropdownSinglevalue({
  id,
  loading = false,
  options,
  setOption,
  placeholder,
  hasFlagImage = false,
  defaultOption,
  isSearchable = true,
  hasError = false,
  height = '',
  hasIndicator = true,
}: DropdownSingleValueProps): JSX.Element {
  const styles = {
    valueContainer: (base: any) => ({
      ...base,
      padding: '0.5rem 0 0.5rem 0.3rem',
      height: height,
    }),
    control: (base: any) => ({
      ...base,
      flexGrow: '1',
      borderRadius: '4px',
      boxShadow: 'none',
      border: hasError ? '1px solid #FE5754' : '1px solid rgb(219, 220, 223)',
      '&:hover': {
        border: hasError ? '1px solid #FE5754' : '1px solid rgb(219, 220, 223)',
      },
    }),
    option: (base: any, state: any) => ({
      ...base,
      padding: '1rem 0.5rem',
      cursor: 'pointer',
      backgroundColor:
        state.isSelected || state.isFocused ? '#F8F8F8' : 'transparent',
      color: state.isSelected ? '#00C3EE' : '#333539',
    }),
    indicatorSeparator: (base: any) => ({ ...base, background: 'transparent' }),
    dropdownIndicator: (base: any) => ({
      ...base,
      paddingLeft: '0',
    }),
  };

  let components = {};

  if (!hasIndicator) {
    components = {
      DropdownIndicator: () => null,
      IndicatorSeparator: () => null,
    };
  }

  function renderOptions(item: TDropDownItem) {
    return (
      <div className="flex justify-start gap-2 items-center">
        {hasFlagImage ? (
          <>
            <span className={`fp ${item.image} w-5`}></span>
            <h2
              className="flex items-center gap-4 pt-0.5 text-left font-bold text-dark-900 leading-4"
              style={{ whiteSpace: 'break-spaces' }}
            >
              {item.title}
            </h2>
          </>
        ) : (
          <span>{item.title}</span>
        )}
      </div>
    );
  }

  return (
    <Select
      id={id}
      instanceId={id}
      components={components}
      styles={styles}
      isLoading={loading}
      isSearchable={isSearchable}
      filterOption={createFilter({
        stringify: (option: FilterOptionOption<TDropDownItem>) =>
          option.data.value,
      })}
      name="single-value"
      onChange={(value) => setOption(value)}
      options={options.sort(sortAlphabetically)}
      placeholder={placeholder || ''}
      formatOptionLabel={renderOptions}
      value={defaultOption}
      // custom props
      // @ts-ignore
      hasFlagImage={hasFlagImage}
    />
  );
}

export { DropdownSinglevalue };

import { useSetAtom } from 'jotai';
import { useDevice } from '../useDevice';
import { useLocation } from '../useLocation';
import { userLoginStatusAtom } from '@/State/global/global.store';
import { loginWithSocial } from '@/Lib/helpers/auth0';
import { ToastError, ToastSuccess } from '@/Components/toast';
import { RudderAnalytics } from '@/Components/ruddarstak/rudderanalytics';

type Props = {
  type: string;
  resetForm: any;
  setIsOpen: any;
  AfterLogin: any;
  setUser: any;
};

const useLoginSocial = ({
  type,
  resetForm,
  setIsOpen,
  AfterLogin,
  setUser,
}: Props) => {
  const { isMD } = useDevice();
  const { location } = useLocation();
  const setUserLoginStatus = useSetAtom(userLoginStatusAtom);

  const loginWith = (brand: string) => {
    loginWithSocial(brand)
      .then((user) => {
        ToastSuccess('Login successful', isMD as boolean);
        setUser(user);
        setUserLoginStatus(true);
        resetForm();
        setIsOpen(false);
        AfterLogin(user);
      })
      .catch((err) => {
        if (err.original !== 'User closed the popup window') {
          ToastError(
            'Something went wrong. Please try later.',
            isMD as boolean
          );
        }
      });
  };

  return { loginWith };
};

export { useLoginSocial };

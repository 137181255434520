import { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { MainFpdcLayout } from './form/mainLayout';
import { InitTab } from './tabs/init';
import { LoginTab } from './tabs/login';
import { LoginSocial } from './tabs/login-social';
import { ForgetPassword } from './tabs/forget-password';
import { SingleValue } from 'react-select';
import { TDropDownItem } from '../drop-down/types';
import { LoginModal } from './login-modal';
import { useSetAtom } from 'jotai';
import { useValidation } from '@/Lib/hooks/fpdc/useValidation';
import { RegisterTab } from './tabs/register';
import { useTemplate } from '@/Lib/hooks/fpdc/useTemplate';
import { Tabs, defaultSharedData, sharedData } from '@/Lib/types/fpdc';
import { TUser } from '@/DataTypes/user';
import { userAtom } from '@/State/global/global.store';

type props = {
  isOpen: boolean;
  setIsOpen: Function;
  AfterLogin: (user: TUser) => void;
  type: string;
};

function Login({ isOpen, setIsOpen, AfterLogin, type }: props): JSX.Element {
  const [templateUpdateData, setTemplateUpdateData] =
    useState<sharedData>(defaultSharedData);
  const { fields, setFields, errors, setErrors, checkFields } = useValidation();
  const { tabData } = useTemplate({ templateUpdateData });
  const setUser = useSetAtom(userAtom);
  const [resultMessage, setResultMessage] = useState('');
  const [tab, setTab] = useState<Tabs>(Tabs.init);
  const [passwordType, setPasswordType] = useState('password');
  const [disabledEmail, setDisabledEmail] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] =
    useState<SingleValue<TDropDownItem>>(null);

  const [isForgetLinkSend, setIsForgetLinkSend] = useState(false);

  function changeHandler(e: React.FormEvent<HTMLInputElement>) {
    e.preventDefault();
    e.stopPropagation();
    setFields({
      ...fields,
      // @ts-ignore
      [e.target.name]: e.target.value,
    });
    // @ts-ignore
    checkFields(tab, e.target.name, e.target.value);
  }

  const resetForm = () => {
    setFields({
      lastName: '',
      firstName: '',
      email: '',
      password: '',
      phoneCode: '',
      phoneNumber: '',
    });
    setErrors({
      lastName: '',
      firstName: '',
      email: '',
      password: '',
      phoneCode: '',
      phoneNumber: '',
    });
    setPasswordType('password');
    setIsForgetLinkSend(false);
  };

  const ChangeTab = (tab: string) => {
    setTab(tab as Tabs);
    if (tab === Tabs.init) {
      setIsForgetLinkSend(false);
    }
  };

  useEffect(() => {
    if (isOpen == false) {
      resetForm();
      setTab(Tabs.init);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <LoginModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <MainFpdcLayout
        tab={tab}
        setIsOpen={setIsOpen}
        resultMessage={resultMessage}
        tabData={tabData}
        type={type}
      >
        {tab === Tabs.init && (
          <InitTab
            changeHandler={(e: React.FormEvent<HTMLInputElement>) =>
              changeHandler(e)
            }
            errors={errors}
            fields={fields}
            tabData={tabData}
            type={type}
            setTab={(tab: Tabs) => setTab(tab)}
            setDisabledEmail={setDisabledEmail}
            checkFields={checkFields}
            resetForm={resetForm}
            setIsOpen={setIsOpen}
            AfterLogin={AfterLogin}
            setTemplateUpdateData={setTemplateUpdateData}
            setUser={setUser}
          />
        )}
        {tab === Tabs.register && (
          <RegisterTab
            changeHandler={(e: React.FormEvent<HTMLInputElement>) =>
              changeHandler(e)
            }
            errors={errors}
            fields={fields}
            passwordType={passwordType}
            setPasswordType={setPasswordType}
            disabledEmail={disabledEmail}
            setSelectedCountryCode={setSelectedCountryCode}
            tabData={tabData}
            selectedCountryCode={selectedCountryCode}
            type={type}
            AfterLogin={AfterLogin}
            checkFields={checkFields}
            resetForm={resetForm}
            setIsOpen={setIsOpen}
            setUser={setUser}
          />
        )}
        {tab === Tabs.login && (
          <LoginTab
            changeHandler={(e: React.FormEvent<HTMLInputElement>) =>
              changeHandler(e)
            }
            errors={errors}
            fields={fields}
            passwordType={passwordType}
            setPasswordType={setPasswordType}
            ChangeTab={(tab: string) => ChangeTab(tab)}
            disabledEmail={disabledEmail}
            tabData={tabData}
            type={type}
            AfterLogin={AfterLogin}
            checkFields={checkFields}
            resetForm={resetForm}
            setIsOpen={setIsOpen}
            setUser={setUser}
          />
        )}
        {tab === Tabs.loginSocial && (
          <LoginSocial
            AfterLogin={AfterLogin}
            resetForm={resetForm}
            setIsOpen={setIsOpen}
            type={type}
            setUser={setUser}
          />
        )}
        {tab === Tabs.forgetPass && (
          <ForgetPassword
            changeHandler={(e: React.FormEvent<HTMLInputElement>) =>
              changeHandler(e)
            }
            errors={errors}
            fields={fields}
            ChangeTab={(tab: string) => ChangeTab(tab)}
            tabData={tabData}
            type={type}
            resultMessage={resultMessage}
            isForgetLinkSend={isForgetLinkSend}
            checkFields={checkFields}
            setIsForgetLinkSend={setIsForgetLinkSend}
            setResultMessage={setResultMessage}
          />
        )}
      </MainFpdcLayout>
    </LoginModal>
  );
}

export { Login };

import { LOGIN_TEMPLATE_COLLECTION } from '@/Lib/graphql/queries/login-template-collection';
import { sharedData } from '@/Lib/types/fpdc';
import { LoginTemplateData } from '@/Lib/types/login';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';

type Props = {
  templateUpdateData: sharedData;
};
const useTemplate = ({ templateUpdateData }: Props) => {
  const [tabData, setTabData] = useState<LoginTemplateData | null>(null);

  const {
    loading: loadingTemplate,
    data: dataTemplate,
    error: dataTemplateError,
  } = useQuery<LoginTemplateData>(LOGIN_TEMPLATE_COLLECTION);

  const replaceData = (dataTemplate: LoginTemplateData) => {
    let data = cloneDeep(dataTemplate);
    data.loginTemplateCollection.items.map((item) => {
      if (item.type === 'login') {
        item.title = item.title.replace('{name}', templateUpdateData.name);
      }
      return item;
    });
    return data;
  };

  useEffect(() => {
    if (!loadingTemplate && dataTemplate) {
      setTabData(replaceData(dataTemplate));
    }
  }, [loadingTemplate, dataTemplate, dataTemplateError, templateUpdateData]);

  return { tabData };
};
export { useTemplate };

import { useEffect, useState } from 'react';
import { useDevice } from '../useDevice';
import { useLocation } from '../useLocation';
import { useSetAtom } from 'jotai';
import { userLoginStatusAtom } from '@/State/global/global.store';
import { useCookies } from 'react-cookie';
import { useMutation } from '@apollo/client';
import { LOGIN_QUERY } from '@/Lib/graphql/queries/auth0.query';
import { ToastError, ToastSuccess } from '@/Components/toast';
import { rudderStackIdentify } from '@/Lib/helpers/auth0';
import { RudderAnalytics } from '@/Components/ruddarstak/rudderanalytics';
import { TFields, Tabs } from '@/Lib/types/fpdc';

type Props = {
  fields: TFields;
  type: string;
  checkFields: any;
  setUser: any;
  resetForm: any;
  setIsOpen: any;
  AfterLogin: any;
};

const useLogin = ({
  fields,
  type,
  checkFields,
  setUser,
  resetForm,
  setIsOpen,
  AfterLogin,
}: Props) => {
  const { isMD } = useDevice();
  const { location } = useLocation();
  const [loading, setLoading] = useState(false);
  const setUserLoginStatus = useSetAtom(userLoginStatusAtom);
  const cacheControlHeader = {
    headers: {
      'Cache-Control': 'max-age=0',
    },
  };
  const [cookies, setCookie, removeCookie] = useCookies([
    'auth',
    'user-login-status',
  ]);

  const [
    loginAuth0,
    {
      loading: loginAuth0Loading,
      data: loginAuth0Data,
      error: loginAuth0Error,
    },
  ] = useMutation<{
    login: {
      success: boolean;
      accessToken: string;
      firstName: string;
      lastName: string;
      email: string;
      phoneCode: string;
      phoneNumber: string;
      statusCode: string;
      code: string;
      message: string;
    };
  }>(LOGIN_QUERY);

  function loginHandler(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    e.stopPropagation();
    const hasErrors = checkFields(Tabs.login);
    if (hasErrors) return;
    setLoading(true);
    loginAuth0({
      variables: {
        email: fields.email.toLowerCase(),
        password: fields.password,
      },
      context: cacheControlHeader,
    });
  }

  useEffect(() => {
    if (!loginAuth0Loading && loginAuth0Error) {
      setLoading(false);
      ToastError('Something went wrong, please try later...', isMD as boolean);
    }

    if (!loginAuth0Loading && !loginAuth0Error && loginAuth0Data) {
      setLoading(false);

      if (loginAuth0Data.login.code && loginAuth0Data.login.message) {
        ToastError(loginAuth0Data.login.message, isMD as boolean);

        return;
      }

      if (loginAuth0Data.login.success) {
        ToastSuccess('Login successful', isMD as boolean);

        setCookie('auth', {
          accessToken: loginAuth0Data.login.accessToken,
        });

        rudderStackIdentify(
          loginAuth0Data.login.firstName,
          loginAuth0Data.login.lastName,
          loginAuth0Data.login.email,
          loginAuth0Data.login.phoneCode,
          loginAuth0Data.login.phoneNumber
        );

        const user = {
          firstName: loginAuth0Data.login.firstName,
          lastName: loginAuth0Data.login.lastName,
          email: loginAuth0Data.login.email,
        };

        setUser(user);
        setUserLoginStatus(true);
        resetForm();
        setIsOpen(false);
        AfterLogin(user);
      } else {
        ToastError(loginAuth0Data.login.message, isMD as boolean);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginAuth0Loading, loginAuth0Data, loginAuth0Error]);

  return { loginHandler, loading };
};
export { useLogin };

import { LoginTemplateData } from '@/Lib/types/login';
import { FormSubTitle } from './form-sub-title';
import { FormTitle } from './form-title';
import { Tabs } from '@/Lib/types/fpdc';

type Props = {
  tab: Tabs;
  setIsOpen: any;
  resultMessage: string;
  children: any;
  tabData: LoginTemplateData | null;
  type: string;
};

const MainFpdcLayout = ({
  tab,
  setIsOpen,
  resultMessage,
  children,
  tabData,
  type,
}: Props) => {
  return (
    <div className="w-[96vw] max-w-full  md:w-[27.5rem] md:min-w-[27.5] md:w-max-[27.5rem]   ">
      <div className="px-4 pt-4 md:px-6 md:pt-6  ">
        <div className="flex w-full items-center">
          <FormTitle tab={tab} tabData={tabData} type={type} />
          <div
            onClick={() => setIsOpen(false)}
            className="text-dark-500 text-xs font-bold cursor-pointer"
          >
            <i className="icon-close"></i>
          </div>
        </div>
        <FormSubTitle tab={tab} tabData={tabData} type={type} />
      </div>
      {children}
    </div>
  );
};
export { MainFpdcLayout };

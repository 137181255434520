import { useCookies } from 'react-cookie';
import { useDevice } from '../useDevice';
import { useEffect, useState } from 'react';
import { useSetAtom } from 'jotai';
import { userLoginStatusAtom } from '@/State/global/global.store';
import { useMutation } from '@apollo/client';
import { REGISTER_QUERY } from '@/Lib/graphql/queries/auth0.query';
import { ToastError, ToastSuccess } from '@/Components/toast';
import { rudderStackIdentify } from '@/Lib/helpers/auth0';
import { RudderAnalytics } from '@/Components/ruddarstak/rudderanalytics';
import { useLocation } from '../useLocation';
import { SingleValue } from 'react-select';
import { TDropDownItem } from '@/Components/drop-down/types';
import { TFields, Tabs } from '@/Lib/types/fpdc';

type Props = {
  fields: TFields;
  selectedCountryCode: SingleValue<TDropDownItem>;
  type: string;
  checkFields: any;
  setUser: any;
  resetForm: any;
  setIsOpen: any;
  AfterLogin: any;
};

const useRegister = ({
  fields,
  selectedCountryCode,
  type,
  checkFields,
  setUser,
  resetForm,
  setIsOpen,
  AfterLogin,
}: Props) => {
  const { isMD } = useDevice();
  const { location } = useLocation();
  const [cookies, setCookie, removeCookie] = useCookies([
    'auth',
    'user-login-status',
  ]);
  const [loading, setLoading] = useState(false);
  const setUserLoginStatus = useSetAtom(userLoginStatusAtom);
  const [
    registerAuth0,
    { loading: registerLoading, data: registerData, error: registerError },
  ] = useMutation<{
    register: {
      success: boolean;
      accessToken: string;
      firstName: string;
      lastName: string;
      email: string;
      phoneCode: string;
      phoneNumber: string;
      statusCode: string;
      code: string;
      message: string;
    };
  }>(REGISTER_QUERY);

  function registerHandler(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    e.stopPropagation();
    const hasErrors = checkFields(Tabs.register);
    if (hasErrors || !selectedCountryCode) return;
    setLoading(true);
    registerAuth0({
      variables: {
        email: fields.email,
        password: fields.password,
        firstName: fields.firstName,
        lastName: fields.lastName,
        phoneCode: selectedCountryCode.value,
        phone: fields.phoneNumber,
      },
    });
  }

  useEffect(() => {
    if (!registerLoading && registerError) {
      setLoading(false);
      ToastError('Something went wrong, please try later...', isMD as boolean);
    }

    if (!registerLoading && !registerError && registerData) {
      setLoading(false);
      if (registerData.register.code && registerData.register.message) {
        ToastError(registerData.register.message, isMD as boolean);
        return;
      }

      if (registerData.register.success) {
        ToastSuccess('Login successful', isMD as boolean);
        setCookie('auth', {
          accessToken: registerData.register.accessToken,
        });
        rudderStackIdentify(
          registerData.register.firstName,
          registerData.register.lastName,
          registerData.register.email,
          registerData.register.phoneCode,
          registerData.register.phoneNumber
        );

        const user = {
          firstName: registerData.register.firstName,
          lastName: registerData.register.lastName,
          email: registerData.register.email,
        };

        setUser(user);
        setUserLoginStatus(true);
        resetForm();
        setIsOpen(false);
        AfterLogin(user);
      } else {
        ToastError(registerData.register.message, isMD as boolean);
      }
    }
  }, [registerLoading, registerData, registerError]);

  return { registerHandler, loading };
};
export { useRegister };

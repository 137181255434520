import { SocialButtons } from '../form/socialButtons';
import { useLoginSocial } from '@/Lib/hooks/fpdc/useLoginSocial';

type Props = {
  type: string;
  resetForm: any;
  setIsOpen: any;
  AfterLogin: any;
  setUser: any;
};

const LoginSocial = ({
  type,
  resetForm,
  setIsOpen,
  AfterLogin,
  setUser,
}: Props) => {
  const { loginWith } = useLoginSocial({
    type,
    resetForm,
    setIsOpen,
    AfterLogin,
    setUser,
  });
  return (
    <div>
      <div className="px-4 pb-4 md:px-6 md:pb-6">
        <div className="flex flex-col gap-y-3 justify-between items-center my-6 md:my-8 px-4 md:px-6">
          <SocialButtons brand="apple" loginWith={loginWith} />
          <SocialButtons brand="google" loginWith={loginWith} />
          {/* <SocialButtons brand="facebook" loginWith={loginWith} /> */}
        </div>
      </div>
    </div>
  );
};
export { LoginSocial };

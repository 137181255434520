import { Input } from '@/Components/form-elements/Input/Input';
import { Eye, EyeCross } from '../icons';
import { Privacy } from '../form/privacy';
import { DropdownSinglevalue } from '@/Components/drop-down/singlevalue';
import {
  PhoneCode,
  PhoneCodeData,
  PhoneCodeType,
} from '@/Lib/types/phone-code';
import { PHONE_CODE_QUERY } from '@/Lib/graphql/queries/phone-code.query';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { LoginTemplateData } from '@/Lib/types/login';
import { ButtonAuth } from '../form/button-auth';
import { useLocation } from '@/Lib/hooks/useLocation';
import { SingleValue } from 'react-select';
import { TDropDownItem } from '@/Components/drop-down/types';
import { useRegister } from '@/Lib/hooks/fpdc/useRegister';
import { TFields, Tabs } from '@/Lib/types/fpdc';

type Props = {
  changeHandler: any;
  errors: TFields;
  fields: TFields;
  passwordType: string;
  setPasswordType: any;
  disabledEmail: boolean;
  setSelectedCountryCode: any;
  tabData: LoginTemplateData | null;
  selectedCountryCode: SingleValue<TDropDownItem>;
  type: string;
  checkFields: any;
  setUser: any;
  resetForm: any;
  setIsOpen: any;
  AfterLogin: any;
};

const RegisterTab = ({
  changeHandler,
  errors,
  fields,
  passwordType,
  setPasswordType,
  disabledEmail,
  setSelectedCountryCode,
  tabData,
  selectedCountryCode,
  type,
  checkFields,
  setUser,
  resetForm,
  setIsOpen,
  AfterLogin,
}: Props) => {
  const { location } = useLocation();
  const { registerHandler, loading } = useRegister({
    fields,
    selectedCountryCode,
    type,
    checkFields,
    setUser,
    resetForm,
    setIsOpen,
    AfterLogin,
  });

  const {
    loading: loadingQuery,
    data: dataPhone,
    error,
  } = useQuery<PhoneCodeData>(PHONE_CODE_QUERY);

  const [dropDownCountryCodesData, setDropDownCountryCodesData] = useState<
    PhoneCodeType[] | null
  >(null);

  useEffect(() => {
    if (!loadingQuery && !error && dataPhone) {
      let resultArray: PhoneCodeType[] = [];
      dataPhone.phoneCodes.map((item: PhoneCode) => {
        resultArray.push({
          name: item.name,
          value: item.dial_code,
          image: item.code.split(',')[0].toLowerCase(),
          title: item.dial_code,
        });
      });

      setDropDownCountryCodesData(resultArray);
    }
  }, [loadingQuery, dataPhone, error]);

  useEffect(() => {
    if (
      !location ||
      !location.country.name ||
      !dropDownCountryCodesData ||
      !dropDownCountryCodesData.length
    ) {
      return;
    }

    const defaultLocation = location.country.name.toLowerCase();
    const defaultcodes = dropDownCountryCodesData?.filter(
      (item) => item.name.toLowerCase() === defaultLocation
    );

    if (defaultcodes && defaultcodes?.length > 0) {
      setSelectedCountryCode(defaultcodes[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.country?.name, dropDownCountryCodesData?.length]);

  return (
    <div className="">
      <div className="px-4 pb-4 md:px-6 md:pb-6">
        <div className={'flex flex-col mt-4 md:mt-6 '}>
          <label className="text-sm font-semibold text-dark-700 text-left leading-4">
            Email address
          </label>
          <Input
            name="email"
            error={errors.email}
            value={fields.email}
            changeHandler={changeHandler}
            errorBorder={true}
            fontColor="text-dark-700"
            classes=" mt-1.5 !rounded-md"
            errorClass=" !text-red-900 !text-2xs font-semibold"
            disabled={disabledEmail}
          />
        </div>

        <div className="mt-4 md:mt-6 grid grid-cols-2 gap-x-6">
          <div className="flex flex-col ">
            <label className="text-sm font-semibold text-dark-700 text-left leading-4">
              First name
            </label>
            <Input
              name="firstName"
              error={errors.firstName}
              value={fields.firstName}
              changeHandler={changeHandler}
              errorBorder={true}
              fontColor="text-dark-700"
              classes=" mt-1.5 !rounded-md"
              errorClass=" !text-red-900 !text-2xs  font-semibold"
            />
          </div>
          <div className="flex flex-col ">
            <label className="text-sm font-semibold text-dark-700 text-left leading-4">
              Last name
            </label>
            <Input
              name="lastName"
              error={errors.lastName}
              value={fields.lastName}
              changeHandler={changeHandler}
              errorBorder={true}
              fontColor="text-dark-700"
              classes=" mt-1.5 !rounded-md"
              errorClass=" !text-red-900 !text-2xs  font-semibold"
            />
          </div>
        </div>

        <div className="flex flex-col mt-4 md:mt-6">
          <label className="text-sm font-semibold text-dark-700 text-left leading-4">
            Phone number
            <span> (optional)</span>
          </label>
          <div className=" flex flex-row gap-x-6">
            <div>
              {dropDownCountryCodesData && (
                <div className="w-[8.125rem] mt-1.5">
                  <DropdownSinglevalue
                    id="app-banner-country-code"
                    options={dropDownCountryCodesData}
                    placeholder="Code"
                    setOption={setSelectedCountryCode}
                    hasFlagImage
                    height="47px"
                    defaultOption={selectedCountryCode}
                  />
                </div>
              )}
            </div>

            <div className="w-full ">
              <Input
                name="phoneNumber"
                error={errors.phoneNumber}
                value={fields.phoneNumber}
                changeHandler={changeHandler}
                errorBorder={true}
                fontColor="text-dark-700"
                classes="!rounded-md mt-1.5 "
                errorClass=" !text-red-900 !text-2xs  font-semibold"
                type="number"
              />
            </div>
          </div>
        </div>

        <div className="mt-4 md:mt-6 flex flex-col relative">
          <label className="text-sm font-semibold text-dark-700 text-left leading-4">
            Password
          </label>
          <Input
            name="password"
            error={errors.password}
            value={fields.password}
            changeHandler={changeHandler}
            type={passwordType}
            errorBorder={true}
            fontColor="text-dark-700"
            classes=" mt-1.5 !rounded-md"
            errorClass=" !text-red-900 !text-2xs  font-semibold"
          />
          <div
            className="absolute top-[2.063rem] right-[1.125rem] cursor-pointer"
            onClick={() =>
              setPasswordType(passwordType === 'password' ? 'text' : 'password')
            }
          >
            {passwordType === 'password' ? EyeCross() : Eye()}
          </div>
        </div>

        <div className="mt-4 md:mt-6  w-full ">
          <ButtonAuth
            tab={Tabs.register}
            tabData={tabData}
            onClick={(e: any) => registerHandler(e)}
            loading={loading}
            type={type}
          />
        </div>
      </div>
      <div className="bg-light-700 px-4 md:px-6 py-2 md:py-4 border-t border-light-800 flex justify-center items-center text-base !leading-4  text-dark-700 font-semibold">
        <Privacy />
      </div>
    </div>
  );
};

export { RegisterTab };

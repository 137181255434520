import { gql } from '@apollo/client';

export const PHONE_CODE_QUERY = gql`
  query PhoneCodeQuery {
    phoneCodes {
      code
      dial_code
      name
    }
  }
`;

import { Input } from '@/Components/form-elements/Input/Input';
import { Eye, EyeCross } from '../icons';
import { LoginTemplateData } from '@/Lib/types/login';
import { ButtonAuth } from '../form/button-auth';
import { useLogin } from '@/Lib/hooks/fpdc/useLogin';
import { TFields, Tabs } from '@/Lib/types/fpdc';

type Props = {
  changeHandler: any;
  errors: TFields;
  fields: TFields;
  passwordType: string;
  setPasswordType: any;
  ChangeTab: any;
  disabledEmail: boolean;
  tabData: LoginTemplateData | null;
  type: string;
  checkFields: any;
  setUser: any;
  resetForm: any;
  setIsOpen: any;
  AfterLogin: any;
};

const LoginTab = ({
  changeHandler,
  errors,
  fields,
  passwordType,
  setPasswordType,
  ChangeTab,
  disabledEmail,
  tabData,
  type,
  checkFields,
  setUser,
  resetForm,
  setIsOpen,
  AfterLogin,
}: Props) => {
  const { loading, loginHandler } = useLogin({
    fields,
    type,
    checkFields,
    setUser,
    resetForm,
    setIsOpen,
    AfterLogin,
  });
  return (
    <div>
      <div className="px-4 pb-4 md:px-6 md:pb-6">
        <div className={'flex flex-col mt-4 md:mt-6 '}>
          <label className="text-sm font-semibold text-dark-700 text-left leading-4">
            Email address
          </label>
          <Input
            name="email"
            error={errors.email}
            value={fields.email}
            changeHandler={changeHandler}
            errorBorder={true}
            fontColor="text-dark-700"
            classes=" mt-1.5 !rounded-md"
            errorClass=" !text-red-900 !text-2xs font-semibold"
            disabled={disabledEmail}
          />
        </div>
        <div className="mt-4 md:mt-6 flex flex-col relative">
          <label className="text-sm font-semibold text-dark-700 text-left leading-4">
            Password
          </label>
          <Input
            name="password"
            error={errors.password}
            value={fields.password}
            changeHandler={changeHandler}
            type={passwordType}
            errorBorder={true}
            fontColor="text-dark-700"
            classes=" mt-1.5 !rounded-md"
            errorClass=" !text-red-900 !text-2xs  font-semibold"
          />
          <div
            className="absolute top-[2.063rem] right-[1.125rem] cursor-pointer"
            onClick={() =>
              setPasswordType(passwordType === 'password' ? 'text' : 'password')
            }
          >
            {passwordType === 'password' ? EyeCross() : Eye()}
          </div>
        </div>

        <div className="mt-4 md:mt-6 w-full ">
          <ButtonAuth
            tab={Tabs.login}
            tabData={tabData}
            onClick={(e: any) => loginHandler(e)}
            loading={loading}
            type={type}
          />
          <div
            onClick={() => ChangeTab(Tabs.forgetPass)}
            className="text-dark-700 font-semibold text-sm sm:text-base text-center cursor-pointer !leading-5 mt-4 md:mt-6 underline "
          >
            Forgot your password?
          </div>
        </div>
      </div>
    </div>
  );
};

export { LoginTab };
